import Layout from "../components/layout";
// import LazyLoad from '../components/Cards_Slider_Deprecated/carousel'
import NewsBlock from "../components/News/newsblock";
import NewsBlockCN from "../components/News/newsblockCN";
import styled from "styled-components";
import NewsIsland from "../components/News/newsisland";
import NewsIslandCN from "../components//News/newsislandCN";
import C from "../../colors";
import Helmet from "react-helmet";
import SideAds from "../components/Ads/sideads";
import MobileAds from "../components/Ads/mobileads";
import SEO from "../components/seo";
import { FormattedMessage } from "react-intl";
import reverseTransformLocale from "../components/LocaleTransform/reverseTransformLocale"

import React from "react";

const NewsStyle = styled.div`
  margin-top: 0rem;

  @media (max-width: 850px) {
    .sticky-parent {
      display: none !important;
    }
  }

  /* sidebar */
  .sidebar--feature-island {
    position: absolute !important;
    top: 0%;
    bottom: 0;
    left: 98%;
  }

  .sidebar {
    width: 37%;
    margin: 0 20px 0 30px;
    padding-top: 10px;
    position: relative;
    z-index: 10;
  }

  .sidebar {
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  }

  .sticky-parent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  aside {
    display: block;
  }

  .sticky-top-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .sticky-top-wrap .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    bottom: 0;
  }

  /* .sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;
} */

  .sidebar-widget--event,
  .sidebar-widget--extra-crunch {
    font-family: aktiv-grotesk, sans-serif;
    letter-spacing: -0.05em;
  }

  @media (min-width: 1100px) {
    .sidebar-widget {
      margin-left: 15%;
      width: 85%;
    }
  }

  @media (min-width: 450px) {
    .sidebar-widget {
      margin: 10px 0;
    }
  }
  .sidebar-widget {
    margin: 20px;
  }
  .sidebar-widget {
    margin-bottom: 25px;
  }

  /* end sidebar */

  @media (min-width: 850px) {
    .content-wrap {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      max-width: 75% !important;
    }
  }

  .content-wrap {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    .content-wrap,
    .site-footer {
      margin-left: 0;
      margin-right: auto;
    }
  }

  @media (min-width: 700px) {
    .content-wrap {
      -webkit-transition: margin-right, margin-left 0.45s ease 75ms;
      transition: margin-right, margin-left 0.45s ease 75ms;
      margin-right: 0;
    }
  }

  .river {
    position: relative;
    min-height: 200px;
  }

  *,
  :after,
  :before {
    background-repeat: no-repeat;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  div {
    display: block;
  }

  .river__title--home {
    font-size: 18px;
    font-size: 1.125rem;
    color: ${C.darker};
    margin: 4px 0;
    letter-spacing: -0.6px;
    font-weight: 600;
  }

  #searcbar {
    z-index: 20;
  }
`;

const addJSONLDNews = {
  __html: `{
          "@context": "http://schema.org",
          "@type": "Blog",
          "name": "EOS Go ",
          "url": "https://eosgo.io/news",
          "sameAs": [
            "https://www.facebook.com/eosgoio/",
            "https://twitter.com/go_eos"
          ]
        }`
};

const addJSONLD = (ogDescription, postDate, ogTitle, ogImage, url) => {
  return {
    __html: `{
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${url}"
        },
        "headline": "${ogTitle}",
        "image": [
          "${ogImage}"
        ],
        "datePublished": "${postDate}",
        "dateModified": "${postDate}",
        "author": {
          "@type": "Person",
          "name": "EOS Go"
        },
        "publisher": {
          "@type": "Organization",
          "name": "EOS Go",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.eosgo.io/static/share.jpg"
          }
        },
        "description": "${ogDescription}",
        "sameAs": [
          "https://www.facebook.com/eosgoio/",
          "https://twitter.com/go_eos"
        ]
      }`
  };
};

const News = ({ pageContext }) => {
  let url = `https://www.eosgo.io/news/`;
  let SEOTitle
  let SEODescription

  let { locale } = pageContext;
  let localeNewsBlock;
  let localeNewsIsland;
  if (locale === "en") {
    localeNewsBlock = <NewsBlock />;
    localeNewsIsland = <NewsIsland />;
    SEOTitle = "EOS Latest News | EOS Go";
    SEODescription = "EOS Go is the leader in EOSIO News. Take a look at the latest EOS cryptocurrency & EOSIO News and get an overview of the EOS ecosystem. We offer the latest information on EOS news, price movement, and adoption.";

  } else if (locale === "cn") {
    localeNewsBlock = <NewsBlockCN />;
    localeNewsIsland = <NewsIslandCN />;
    SEOTitle = "EOS最新消息 | EOS 行情资讯和影响市场价格的时事新闻";
    SEODescription = "EOS Go是《 EOSIO新闻》的领军者。不仅包括最新的EOS最新价格，行情消息和EOS币价，和也包括区块链权威新闻（比特币，以太坊），EOS主链、侧链、RAM、CPU、Dapp等一切消息。EOS Go是区块链开发者和EOS持币人、投资人了解并深度参与整个EOS和区块链生态系统的必备官网。EOS Go为EOS社区打造了一种很良性的互动,形成自己节点生态内平衡。";

  }

  return (
    <Layout locale={reverseTransformLocale(locale)}>
      <SEO title={SEOTitle} description={SEODescription} />
      <NewsStyle>
        <Helmet>
          {/* here we inject the JSON-LD from the function into a script tag  */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={addJSONLDNews}
          />
        </Helmet>
        <div className="main-content">
          <div className="content-wrap">
            <div className="content">
              <div>{localeNewsIsland}</div>
            </div>
          </div>

          {/* LIST OF NEWS BEGINS */}
          {console.log(`this is the news locale ${locale}`)}
          <div className="content-wrap">
            <h2 className="river__title river__title--home">
              <FormattedMessage id="news-latest" />
            </h2>
            <div className="river river--homepage">
              {/* SIDEBAR BEGINS */}

              <SideAds />

              {/* SIDEBAR ENDS */}

              <div>{localeNewsBlock}</div>
            </div>
          </div>

          {/* LIST OF NEWS ENDS */}
        </div>
      </NewsStyle>
      {/* )} */}
      {/* MOBILE AD BEGINS */}
      <MobileAds />

      {/* MOBILE AD ENDS */}
    </Layout>
  );
};

export default News;
