import React from "react";
import styled from "styled-components";
import C from "../../../colors";
import dataCount from "../../data/news-feed-cn.json";
import { StaticQuery, graphql } from "gatsby";

import InfiniteScroll from "react-infinite-scroller";

//MODIFY THIS EACH TIME YOU CREATE A NEW LOCATE. IN THIS CASE ITS CN
let localePath = 'cn'

let DATAP = dataCount.edges;
//necessary to fix a bug with the infinite load
let DATAlength = DATAP.length;
let newCount = 6;
let remaining;

const Block = styled.a`
  display: block;

  position: relative;
  margin-top: 1em;
  margin-bottom: 1.725em;
  padding-top: 1.725em;
  border-top: 2px solid #f1f1f1;
  clear: both;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.2s ease 0.1s;
  transition: all 0.2s ease 0.1s;
  opacity: 1;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
    text-decoration: none;
  }

  .post-block__header {
    -webkit-flex-basis: 69%;
    -ms-flex-preferred-size: 69%;
    flex-basis: 69%;
  }

  .post-block__title {
    font-size: 22px;
    font-size: 1.375rem;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0.25em;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 800;
    line-height: 1.09;
    letter-spacing: -0.7px;
    color: ${C.darker};
  }

  @media screen and (min-width: 850px) {
    .post-block__title {
      margin-bottom: 10px;
    }
  }

  .post-block__meta {
    position: relative;
    bottom: 0;
    left: 0;
  }

  @media screen and (min-width: 850px) {
    .post-block__meta {
      position: relative;
      bottom: 0;
      left: 0;
    }
  }

  .post-block__content {
    display: none;
    cursor: pointer;
  }

  *,
  :after,
  :before {
    background-repeat: no-repeat;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  img {
    float: right;
    max-width: 90%;
    height: auto;
    border-style: none;
    vertical-align: middle;
  }

  @media screen and (min-width: 850px) {
    .post-block__header {
      -webkit-flex-basis: 28%;
      -ms-flex-preferred-size: 28%;
      flex-basis: 28%;
      min-width: 0;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
    }
  }

  @media screen and (min-width: 850px) {
    .post-block--funding .post-block__content,
    .post-block--image .post-block__content,
    .post-block--sponsored--has-image .post-block__content {
      -webkit-flex-basis: 32%;
      -ms-flex-preferred-size: 32%;
      flex-basis: 32%;
    }
  }

  @media screen and (min-width: 850px) {
    .post-block__content {
      font-size: 14px;
      font-size: 0.875rem;
      -webkit-flex-basis: 67.5%;
      -ms-flex-preferred-size: 67.5%;
      flex-basis: 30%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: block;
      /* font-family: Helvetica Neue,Helvetica,Arial,sans-serif; */
      line-height: 1.43;
      color: #777;
    }
  }

  .post-block__content p {
    margin: 0;
  }
  *,
  :after,
  :before {
    background-repeat: no-repeat;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  @media screen and (min-width: 850px) {
    .post-block--funding .post-block__footer,
    .post-block--image .post-block__footer,
    .post-block--sponsored--has-image .post-block__footer {
      -webkit-flex-basis: 31%;
      -ms-flex-preferred-size: 31%;
      flex-basis: 32%;
    }
  }

  .post-block--funding .post-block__footer,
  .post-block--image .post-block__footer,
  .post-block--sponsored--has-image .post-block__footer {
    -webkit-flex-basis: 29%;
    -ms-flex-preferred-size: 29%;
    flex-basis: 28%;
  }

  .post-block--funding .post-block__footer figure,
  .post-block--image .post-block__footer figure,
  .post-block--sponsored--has-image .post-block__footer figure {
    cursor: pointer;
  }
  .post-block__media {
    margin: 0;
    opacity: 1;
    cursor: pointer;
  }

  .footer {
    flex-basis: 28% !important;
    display: block !important;
  }

  .river-byline__author {
    font-weight: 700;
    line-height: 1.23;
    color: #333;
  }

  .river-byline__full-date-time__wrapper {
    color: #777;
  }
`;

class NewsBlock extends React.Component {
  //necessary for infinite scroll
  constructor(props) {
    super(props);
    this.state = {
      items: 6,
      hasMoreItems: true
    };
  }

  //necessary for infinite scroll
  loadMore() {
    remaining = DATAlength - newCount;
    if (this.state.items > 100 || this.state.items === DATAlength) {
      this.setState({ hasMoreItems: false });
    } else if (remaining < 6 && remaining > 0) {
      newCount = newCount + remaining;
      setTimeout(() => {
        this.setState({ items: newCount });
        this.setState({ hasMoreItems: false });
      }, 2000);
      console.log(`remaining less that 6: ${remaining}`);
    } else if (remaining >= 6) {
      newCount = newCount + 6;
      setTimeout(() => {
        this.setState({ items: this.state.items + 6 });
      }, 2000);
    }
  }

  //necessary for infinite scroll
  showItems(data) {
    var items = [];

    let imageSrc 
    

    for (var i = 0; i < newCount; i++) {
      //fallback image 
      if (data.posts.edges[i].node.featuredImage.file !== null) {
        imageSrc = data.posts.edges[i].node.featuredImage.file.url
      } else {
        for (let j=0; j < data.fallbackImage.edges.length; j++){
          if(data.posts.edges[i].node.contentful_id === data.fallbackImage.edges[j].node.contentful_id){
            imageSrc = data.fallbackImage.edges[j].node.featuredImage.file.url
          }
        }
      }

      let dataExcerpt = data.posts.edges[i].node.excerpt.excerpt;
      //searching for description right after the image in order to start and end at the right spot. this is needed to keep a short description for each news.
      let positionSpace = dataExcerpt.search("\n\n");
      let positionToStart = positionSpace + 2;
      let positionToEnd = positionSpace + 121;
      items.push(
        <Block
          href={`/${localePath}/news/${data.posts.edges[i].node.slug}`}
          title={`${data.posts.edges[i].node.title}`}
        >
          <header className="post-block__header">
            <h2 className="post-block__title">
              {data.posts.edges[i].node.title}
            </h2>
            <div className="post-block__meta">
              <div className="river-byline">
                <span className="river-byline__author">
                  {data.posts.edges[i].node.news_maker}
                </span>
                <div className="river-byline__full-date-time__wrapper">
                  {data.posts.edges[i].node.date}
                </div>
              </div>
            </div>
          </header>
          <div className="post-block__content">
            <p>{`${data.posts.edges[i].node.excerpt.excerpt.substring(
              positionToStart,
              positionToEnd
            )}...`}</p>
          </div>
          <div className="footer post-block__footer">
            <figure className="post-block__media">
              <img  
                src={ imageSrc }
                sizes="(max-width: 430px) 100vw, 430px"
              />
            </figure>
          </div>
        </Block>
      );
    }
    return items;
  }

  render() {


    return (
      <StaticQuery
        query={newsBlockQuery}
        render={data => (
          <div>
            <InfiniteScroll
              loadMore={this.loadMore.bind(this)}
              hasMore={this.state.hasMoreItems}
              loader={<div className="loader"> Loading... </div>}
              useWindow={true}
            >
              {this.showItems(data)}{" "}
            </InfiniteScroll>{" "}
          </div>
        )}
      />
    );
  }
}

export default NewsBlock;

const newsBlockQuery = graphql`
  query {
    posts: allContentfulNews (sort: {fields: date, order: DESC}, filter: {slug: {ne: null}, node_locale: {eq: "zh-CN"}}) {
      edges {
        node {
          contentful_id
          excerpt {
            excerpt
          }
          news_maker
          title
          slug
          date
          featuredImage {
            file {
              url
            }
          }
        }
      }
    }
    fallbackImage: allContentfulNews (sort: {fields: date, order: DESC}, filter: {title: {ne: null}, node_locale: {eq: "en-US"}}) {
      edges {
        node {
          contentful_id
          featuredImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
