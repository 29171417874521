import React from "react";
import styled from "styled-components";
import C from "../../../colors";
import { StaticQuery, graphql } from "gatsby";

//MODIFY THIS EACH TIME YOU CREATE A NEW LOCATE. IN THIS CASE ITS CN
let localePath = 'cn'

const Island = styled.div`
  /* Feature Island */

  /* .feature-island {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
} */

  .feature-island-main-block {
    padding-bottom: 10px;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-right: 2%;
    opacity: 1;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }

  .fi-main-block__title {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1;
    margin: 0 0 10px;
    font-weight: 800;
    letter-spacing: -0.05em;
  }

  .fi-main-block__title a {
    color: ${C.darker} !important;
  }

  .fi-main-block__byline {
    font-size: 13px;
    font-size: 0.8125rem;

    font-weight: 700;
    color: #333;
  }

  .fi-main-block__byline a {
    color: inherit;
    border-bottom: 0;
  }

  .post-block__title__link {
    color: inherit;
  }

  .post-block__media {
    position: relative;
    margin: 0;
    opacity: 1;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: auto;
    border-style: none;
    vertical-align: middle;
  }

  /* mini-view */

  .mini-view {
    padding-left: 0;
    padding-bottom: 0.875em;
    margin-top: 1em;
    margin-left: 20px;
    margin-right: 20px;
  }

  .mini-view__item {
    margin-bottom: 1em;
    border-bottom: 1px solid #ddd;
    opacity: 1;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    display: block;
  }

  .mini-view__item__title {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 0;
    margin-bottom: 0.25em;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 800;
    line-height: 1.11;
    letter-spacing: -0.7px;
    color: ${C.darker} !important;
  }

  .fi-main-block__byline {
    font-size: 13px;
    font-size: 0.8125rem;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 700;
    color: #333 !important;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  a {
    color: ${C.darker} !important;
  }

  .mini-view-writer {
    color: #777 !important;
  }

  /* hover */

  .feature-island-main-block:hover {
    opacity: 0.7;
  }

  .mini-view__item:hover {
    opacity: 0.7;
  }

  /* media queries */

  @media (min-width: 450px) {
    .mini-view {
      padding-bottom: 0;
      border-bottom: none;
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 850px), screen and (min-width: 550px) {
    .feature-island {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }

  @media (min-width: 450px) {
    .mini-view {
      padding-bottom: 0;
      border-bottom: none;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export default class NewsIsland extends React.Component {

  mainItem(data){
    var item = [];

    let imageSrc 
    //fallback image 
    if (data.posts.featured_posts[0].featuredImage.file !== null) {
      imageSrc = `https://${data.posts.featured_posts[0].featuredImage.file.url}`
    } else {
      for (let i=0; i < data.fallback.edges.length; i++){
        if (data.posts.featured_posts[0].contentful_id === data.fallback.edges[i].node.contentful_id){
          imageSrc = `https://${data.fallback.edges[i].node.featuredImage.file.url}`
        }
      }
    }

    item.push(
      <Island>
            <div className="feature-island">
              <div className="feature-island-main-block fi-main-block--unread">
                <h2 className="fi-main-block__title">
                  <a
                    className="post-block__title__link"
                    href={`/${localePath}/news/${data.posts.featured_posts[0].slug}`}
                  >
                    {console.log(
                      `let it be: ${data.posts.featured_posts[0].slug}`
                    )}

                    {data.posts.featured_posts[0].title}
                  </a>
                </h2>
                <p className="fi-main-block__byline">
                  <span>
                    <a
                      className="mini-view-writer"
                      href={`/${localePath}/news/${data.posts.featured_posts[0].slug}`}
                    >
                      {" "}
                      {data.posts.featured_posts[0].news_maker}
                    </a>
                  </span>
                </p>
                <a
                  className="post-block__title__link"
                  href={`/${localePath}/news/${data.posts.featured_posts[0].slug}`}
                >
                  <img
                    className="post-block__media"
                    src={imageSrc}
                    alt={`image: ${data.posts.featured_posts[0].title}`}
                  ></img>
                </a>
              </div>
              <div className="mini-view">{this.miniViewItem(data)}</div>
            </div>
          </Island>
    ); 
    return item;
  }


  miniViewItem(data) {
    var items = [];


    for (let i = 1; i < 5; i++) {

      items.push(
            <div className="mini-view__item mini-item--unread">
              <h3 className="mini-view__item__title">
                <a
                  href={`/${localePath}/news/${data.posts.featured_posts[i].slug}`}
                >
                  {data.posts.featured_posts[i].title}
                </a>
              </h3>
              <p className="fi-main-block__byline">
                <span>
                  <a
                    className="mini-view-writer"
                    href={`/${localePath}/news/${data.posts.featured_posts[i].slug}`}
                  >
                    {data.posts.featured_posts[i].news_maker}
                  </a>
                </span>
              </p>
            </div>
      );
    }
    return items;
  }

  render() {
    return (
      <StaticQuery
        query={newsIslandQuery}
        render={data => (
          <div>{this.mainItem(data)}</div>
        )}
      />
    );
  }
}

const newsIslandQuery = graphql`
  query {
    posts: contentfulFeaturedNews (node_locale: {eq: "zh-CN"}, title: {ne: null}) {
      featured_posts {
        contentful_id
        title
        slug
        news_maker
        featuredImage {
          file {
            url
          }
        }
      }
    }
    fallback: allContentfulNews(filter: {node_locale: {eq: "en-US"}, title: {ne: null}}) {
      edges {
        node {
          node_locale
          slug
          featuredImage {
            file {
              url
            }
          }
          contentful_id
        }
      }
    }
  }
`;
